export const modalContainerStyle: React.CSSProperties = {
  background: `url("/PC/background/registerModalBg.webp") no-repeat`,
  backgroundSize: "cover",
  width: `calc(100% - 62.29vw)`,
  height: `calc(100vh - 10vh)`,
  minWidth: "724px",
};

export const closeWrapperStyle: React.CSSProperties = {
  padding: "2vh 20px",
};
export const closeImgStyle: React.CSSProperties = {
  width: "1.5rem",
  height: "1.5rem",
};
