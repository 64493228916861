import React from "react";
import styles from "./styles.module.scss";

import { pcSuccessStyles } from "./pcSuccessStyles";
import FormSubTitle from "../FormSubTitle";
import { getPcStyle } from "@katsuwin/share-utils";
import twitterButtonPath from "../../images/button/twitterButton.png";
import officialButtonPath from "../../images/button/officialButton.png";
import subTitleSuccessDark from "../../images/dark/formSubTitleSuccess.svg";
import subTitleSuccessLight from "../../images/light/formSubTitleSuccess.svg";

const SignUpSuccess = ({
  isPc,
  mode,
}: {
  isPc: boolean;
  mode: "dark" | "light";
}) => {
  const textColor = mode === "dark" ? { color: "fff" } : { color: "#141E2B" };

  return (
    <div
      className={styles.modal}
      style={getPcStyle(isPc, pcSuccessStyles.modal)}
    >
      <h1
        className={styles[`form_title_${mode}`]}
        style={getPcStyle(isPc, pcSuccessStyles.title)}
      >
        新規登録で5,000円
      </h1>
      <div
        className={styles.form_sub_title_wrapper}
        style={getPcStyle(isPc, pcSuccessStyles.form_sub_title_wrapper)}
      >
        <FormSubTitle
          imgPath={mode === "dark" ? subTitleSuccessDark : subTitleSuccessLight}
          isPc={isPc}
        />
      </div>
      <div
        className={styles.p_wrapper}
        style={getPcStyle(isPc, pcSuccessStyles.pWrapper)}
      >
        <p style={textColor}>
          登録が完了いたしました。KatsuWINのグランドオープンイベントにご参加くださり、誠にありがとうございます。
        </p>
        <p style={textColor}>
          下部にあるリンクをクリックし、ご登録いただいたアカウントでログインしていただくと、無料体験ボーナス4,000円がゲットでき、当サイト内のゲームでご利用いただけます。
        </p>
        <p style={textColor}>公式サイトへのご訪問をお待ちしております。</p>
      </div>
      <div
        className={styles.links_wrapper}
        style={getPcStyle(isPc, pcSuccessStyles.link_wrapper)}
      >
        <a href="https://www.Katsuwin.io" className={styles.link}>
          <img src={officialButtonPath} alt="twitter" />
        </a>
        <a href="https://twitter.com/katsuwinonline">
          <img className={styles.link} src={twitterButtonPath} alt="twitter" />
        </a>
      </div>
    </div>
  );
};

export default SignUpSuccess;
