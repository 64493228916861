//for v16 v17 v18 v19 home page layout

import MobileHome from "../../components/HomePage-1/Mobile";
import PcHome from "../../components/HomePage-1/Pc";
import { HomePageOneProps } from "../../types/homPageType";
import ReduxProvider from "../../store/ReduxProvider";
import { useDetectDevices } from "@katsuwin/share-hooks";

function HomePageOneProvider(props: HomePageOneProps) {
  const { isMobile } = useDetectDevices();
  return (
    <ReduxProvider>
      {isMobile ? <MobileHome {...props} /> : <PcHome {...props} />}
    </ReduxProvider>
  );
}

export default HomePageOneProvider;
