export type PcSignUpModalStyles = {
  modal: React.CSSProperties;
  formTitle: React.CSSProperties;
  formContainer: React.CSSProperties;
  label: React.CSSProperties;
  input: React.CSSProperties;
  warning: React.CSSProperties;
  submit: React.CSSProperties;
  submitButton: React.CSSProperties;
  formSubTitleP: React.CSSProperties;
  downArrow: React.CSSProperties;
};
export const pcSignUpModalStyles: PcSignUpModalStyles = {
  modal: {
    gap: "2vh",
    height: "82vh",
    padding: "0 0 0 0",
  },
  formTitle: {
    fontSize: "3vw",
    fontWeight: "400",
    lineHeight: "normal",
  },
  formContainer: {
    padding: "0 132px",
    gap: "1.2vh",
  },
  label: {
    fontSize: "16px",
  },
  input: {
    fontSize: "18px",
  },
  warning: {
    fontSize: "13px",
  },
  submit: {
    padding: "2.5vh 0 0 0",
  },
  submitButton: {
    width: "307px",
    height: "90px",
  },
  formSubTitleP: {
    fontSize: "20px",
  },
  downArrow: {
    width: "60px",
    height: "60px",
    right: "30px",
    bottom: "60px",
  },
};
