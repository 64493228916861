import React from "react";
import styles from "./style.module.scss";

const SignUpSuccess = () => {
  return (
    <div className={styles.container}>
      <section>
        <img src="/signUpSuccess/icon_check_mark.png" alt="check icon" />
        <h1>おめでとうございます!</h1>
        <p>おめでとうございます 登錄に成功しました</p>
      </section>

      <section>
        <a href="https://www.katsuwin.io/" target="_blank" rel="noreferrer">
          公式ウェブサイト
        </a>
        <a
          href="https://twitter.com/katsuwinonline"
          target="_blank"
          rel="noreferrer"
        >
          公式Ｘ (旧Twitter)
        </a>
      </section>
    </div>
  );
};

export default SignUpSuccess;
