type PcSignUpModalStyles = {
  modal: React.CSSProperties;
  formTitle: React.CSSProperties;
  formContainer: React.CSSProperties;
  label: React.CSSProperties;
  input: React.CSSProperties;
  warning: React.CSSProperties;
  submit: React.CSSProperties;
  submitButton: React.CSSProperties;
  formSubTitleImg: React.CSSProperties;
  downArrow: React.CSSProperties;
};
export const pcSignUpModalStyles: PcSignUpModalStyles = {
  modal: {
    gap: "2vh",
    height: "82vh",
    padding: "0 0 0 0",
  },
  formTitle: {
    color: "#fff",
    fontFamily: "Koruri",
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "1.2px",
  },
  formContainer: {
    padding: "0 132px",
    gap: "20px",
  },
  label: {
    fontSize: "16px",
  },
  input: {
    fontSize: "18px",
    borderRadius: "25px",
    border: "1px solid #CFD3D9",
  },
  warning: {
    fontSize: "13px",
  },
  submit: {
    padding: "2.5vh 0 0 0",
  },
  submitButton: {
    width: "100%",
    height: "auto",
  },

  formSubTitleImg: {
    width: "132px",
    height: "45px",
  },
  downArrow: {
    width: "60px",
    height: "60px",
    right: "30px",
    bottom: "60px",
  },
};
