import {
  createBrowserRouter,
} from "react-router-dom";
import { HomePagePreview, PlayPagePreview } from "../components/lazyComponents";
import { Suspense } from "react";
import { ClimbingBoxLoader } from "react-spinners";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<ClimbingBoxLoader color="#3B9CDA" />}>
        <HomePagePreview />
      </Suspense>
    ),
  },
  {
    path: "/play",
    element: (
      <Suspense fallback={<ClimbingBoxLoader color="#3B9CDA" />}>
        <PlayPagePreview />
      </Suspense>
    ),
  },
]);
