import { ModalType } from "@katsuwin/share-components/src/types/modal";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface modalState {
  modalType: ModalType;
  // selectTheme: ThemeColor;
  // token: string;
}

const initialState: modalState = {
  modalType: "",
  // selectTheme: "dark",
  // token: "",
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalType: (state, action: PayloadAction<ModalType>) => {
      state.modalType = action.payload;
    },
    // setSelectTheme: (state, action: PayloadAction<ThemeColor>) => {
    //   state.selectTheme = action.payload;
    // },
    // decrement: (state) => {
    //   state.counter = 1;
    // },
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.counter = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { setModalType } = modalSlice.actions;

export default modalSlice.reducer;
