type PcSuccessStyles = {
  modal: React.CSSProperties;
  title: React.CSSProperties;
  pWrapper: React.CSSProperties;
  p: React.CSSProperties;
  katsuwin_link: React.CSSProperties;
};
export const pcSuccessStyles: PcSuccessStyles = {
  modal: {
    padding: "0 87px",
  },
  title: {
    fontSize: "48px",
    padding: "15px 0 20px 0",
  },
  pWrapper: {
    gap: "20px",
    padding: "0 0 4vw 0",
  },
  p: {
    fontSize: "20px",
  },
  katsuwin_link: {
    padding: "0 0 50px 0",
    fontSize: "20px",
  },
};
