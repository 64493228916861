import React from "react";
import styles from "./styles.module.scss";
import { pcSignUpModalStyles } from "../SignUpModal/pcSignUpStyle";
import { getPcStyle } from "@katsuwin/share-utils";

const FormSubTitle = ({
  imgPath,
  isPc,
}: {
  imgPath: string;
  isPc: boolean;
}) => {
  return (
    <div className={styles.form_sub_title_block}>
      <img
        src={imgPath}
        alt="form Sub Title"
        width={98}
        height={37}
        style={getPcStyle(isPc, pcSignUpModalStyles.formSubTitleImg)}
      />
    </div>
  );
};

export default FormSubTitle;
