type PcSuccessStyles = {
  modal: React.CSSProperties;
  title: React.CSSProperties;
  form_sub_title_wrapper: React.CSSProperties;
  pWrapper: React.CSSProperties;
  p: React.CSSProperties;
  link_wrapper: React.CSSProperties;
};
export const pcSuccessStyles: PcSuccessStyles = {
  modal: {
    padding: "0 84px",
  },
  title: {
    fontSize: "24px",
    padding: "15px 0 20px 0",
    letterSpacing: "1.2px",
  },
  form_sub_title_wrapper: {
    padding: "84px 0 64px 0",
  },
  pWrapper: {
    gap: "20px",
    padding: "0 0 64px 0",
  },
  p: {
    fontSize: "20px",
  },
  link_wrapper: {
    gap: "24px",
  },
};
