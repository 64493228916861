import { useEffect } from "react";
import { ModalType } from "../../types/modal";

const HandleFrozen = ({ modalType }: { modalType: ModalType }) => {
  useEffect(() => {
    if (!modalType) {
      document.body.style.overflow = "scroll";
      return;
    }
    document.body.style.overflow = "hidden";
  }, [modalType]);
  return null;
};

export default HandleFrozen;
