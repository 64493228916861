import styles from "./styles.module.scss";

type ScrollToBottomBtnProps = {
  img: string;
  pcStyle?: React.CSSProperties;
  onClick: () => void;
};

const ScrollToBottomBtn = ({
  img,
  pcStyle,
  onClick,
}: ScrollToBottomBtnProps) => {
  return (
    <img
      className={styles.down_arrow}
      src={img}
      alt="Scroll To Bottom Button"
      style={pcStyle}
      onClick={onClick}
    />
  );
};

export default ScrollToBottomBtn;
