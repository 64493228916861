type PcSuccessStyles = {
  modal: React.CSSProperties;
  title: React.CSSProperties;
  form_sub_title_wrapper: React.CSSProperties;
  pWrapper: React.CSSProperties;
  p: React.CSSProperties;
  link_wrapper: React.CSSProperties;
};
export const pcSuccessStyles: PcSuccessStyles = {
  modal: {
    padding: "0 65px",
  },
  title: {
    fontSize: "32px",
  },
  form_sub_title_wrapper: {
    padding: "42px 0 24px",
  },
  pWrapper: {
    gap: "20px",
    padding: "0 0 64px 0",
  },
  p: {
    fontSize: "20px",
  },
  link_wrapper: {
    gap: "24px",
  },
};
