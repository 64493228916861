import {
  Analytics,
  CustomModalProvider,
  HandleFrozen,
} from "@katsuwin/share-components";
import {
  useAppDispatch,
  useAppSelector,
} from "@katsuwin/share-components/src/hooks/hooks";
import React from "react";
import { setModalType } from "./store/features/modalSlice";

function App({ children = <></> }: { children?: React.ReactNode }) {
  const { modalType } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  return (
    <div className="App">
      <Analytics
        matomoId={process.env.REACT_APP_MATOMO_ID || ""}
        fbIdsStr={process.env.REACT_APP_PIXEL_IDS || ""}
        gaId={process.env.REACT_APP_GA_ID || ""}
      />
      <HandleFrozen modalType={modalType} />
      <CustomModalProvider
        modal_type={modalType}
        set_modal_type={(modalType) => dispatch(setModalType(modalType))}
        modal_version="v3"
        promotion_code={process.env.REACT_APP_PROMOTION_CODE ?? ""}
      />
      {children}
    </div>
  );
}

export default App;
