import React, { useEffect, useRef, useState, useMemo } from "react";
import styles from "./styles.module.scss";

import useVerifyEmail from "./useVerifyEmail";
import { pcSignUpModalStyles } from "./pcSignUpStyle";
import useVerifyPhone from "./useVerifyPhone";
import usePersonalInfo from "./usePersonalInfo";
import FormSubTitle from "../FormSubTitle";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import {
  postSignUpFormSubmitThunk,
  resetSubmitInfo,
} from "../../../../../store/features/signUpSlice";
import {
  getPcStyle,
  gtag_report_conversion,
  isAtBottom,
  matoTrace,
  scrollToBottom,
} from "@katsuwin/share-utils";
import { setModalType } from "../../../../../store/features/modalSlice";
import formSubmitPath from "../../images/button/formSubmit.png";
import japanIconPath from "../../../images/icon/japan.png";
import formSubTitlePath from "../../images/formSubTitle.png";
import formSubTitleNamesPath from "../../images/formSubTitleNames.png";
import ScrollToBottomBtn from "../../../ScrollToButtonBtn";
import downArrowPath from "../../../images/icon/down_arrow_light.png";

type FormTarget = {
  username: { value: string };
  newPassword: { value: string };
  confirmPassword: { value: string };
  email: { value: string };
  emailValidateCode: { value: string };
  phone: { value: string };
  romanizationFst: { value: string };
  romanizationLast: { value: string };
  nameFst: { value: string };
  nameLast: { value: string };
};

type SignUpModalProps = {
  isPc: boolean;
  promotion_code: string;
  gaId: string;
  adEventTriggerId: string;
};

type PasswordType = {
  newPassword: string;
  confirmPassword: string;
};

const SignUpModal = ({
  isPc,
  promotion_code,
  gaId,
  adEventTriggerId,
}: SignUpModalProps) => {
  const dispatch = useAppDispatch();
  const { forSubmitRes, formSubmitError, emailVerifyTimer, emailVerifyRes } =
    useAppSelector((state) => state.signUp);
  const formRef = useRef<HTMLFormElement>(null);

  const [submitEmail, setSubmitEmail] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");
  const [isDownBtnVisible, setIsDownBtnVisible] = useState(true);

  const [passWord, setPassWord] = useState<PasswordType>({
    newPassword: "",
    confirmPassword: "",
  });
  const { phone, isCorrectPhone, handlePhoneChange } = useVerifyPhone();
  const {
    email,
    isCorrectEmail,
    isStartTimer,
    isDisable,
    handleVerifyEmail,
    handleEmailChange,
  } = useVerifyEmail();

  const {
    handleRomanizationFstChange,
    handleRomanizationLastChange,
    handleNameFstChange,
    handleNameLastChange,
  } = usePersonalInfo();

  const isSubmiting = useRef(false);

  const handleSubmit = (e: React.SyntheticEvent) => {
    // 👇️ prevent page refresh
    e.preventDefault();
    const target = e.target as typeof e.target & FormTarget;
    const username = target.username.value; // typechecks!
    const newPassword = target.newPassword.value; // typechecks!
    const confirmPassword = target.confirmPassword.value; // typechecks!
    const email = target.email.value; // typechecks!
    const phone = target.phone.value;
    const romanizationFst = target.romanizationFst.value;
    const romanizationLast = target.romanizationLast.value;
    const nameFst = target.nameFst.value;
    const nameLast = target.nameLast.value;

    const formatPhone = () => {
      /*     
        ex: 使用者輸入 08012345678
        call api時需轉化為 +81 80-1234-5678
        ex: 使用者輸入 8012345678
        call api時需轉化為 +81 80-1234-5678
      */
      if (phone.length === 11) {
        const formattedPhoneNumber = `+81 ${phone
          .slice(1)
          .replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3")}`;
        return formattedPhoneNumber;
      }
      if (phone.length === 10) {
        const formattedPhoneNumber = `+81 ${phone.replace(
          /(\d{2})(\d{4})(\d{4})/,
          "$1-$2-$3"
        )}`;
        return formattedPhoneNumber;
      }
      return "";
    };

    const formatRomanization = `${romanizationFst},${romanizationLast}`;
    const formatName = `${nameFst},${nameLast}`;
    setSubmitEmail(email);
    const emailValidateCode = target.emailValidateCode.value; // typechecks!
    const formData = new FormData();
    formData.append("username", username);
    formData.append("newPassword", newPassword);
    formData.append("confirmPassword", confirmPassword);
    formData.append("email", email);
    formData.append("emailValidateCode", emailValidateCode);
    formData.append("password", newPassword);
    formData.append("phone", formatPhone());
    formData.append("romanization", formatRomanization);
    formData.append("name", formatName);
    formData.append("code", "katsuwin");
    formData.append("language", "jp");
    formData.append("coupon_code", "katsuwin55");
    if (promotion_code) {
      formData.append("promotion_code", promotion_code);
    }
    dispatch(
      postSignUpFormSubmitThunk({
        body: formData,
      })
    );
    isSubmiting.current = true;
    matoTrace(isPc ? "pc" : "mobile", "regist_click", email);
    gtag_report_conversion({
      gaId: gaId,
      adEventTriggerId: adEventTriggerId,
    });
  };

  const handleChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
    const { id, value } = e?.currentTarget;

    setPassWord((pre) => {
      const cpPassWord = { ...pre, [id]: value };
      return cpPassWord;
    });
  };

  const handleScroll = (event: React.UIEvent<HTMLFormElement>) => {
    setIsDownBtnVisible(isAtBottom(event));
  };
  const isPasswordValid =
    passWord.confirmPassword !== "" &&
    passWord.newPassword !== "" &&
    passWord.confirmPassword !== passWord.newPassword;

  // unmount 時要 reset submit Info
  useEffect(() => {
    return () => {
      dispatch(resetSubmitInfo());
    };
  }, []);

  useEffect(() => {
    if (forSubmitRes?.status === 200) {
      // 登入成功 redirect
      dispatch(setModalType("SIGN_UP_SUCCESS"));
      matoTrace(isPc ? "pc" : "mobile", "regist_success", submitEmail);
      const script = document.createElement("script");

      //@ts-ignore
      script.setAttribute("id", "Dable_CompleteRegistration");
      script.type = "text/javascript";
      script.innerHTML = `(function (d, a, b, l, e, _) {
         d[b] =
           d[b] ||
           function () {
             (d[b].q = d[b].q || []).push(arguments);
           };
         e = a.createElement(l);
         e.async = 1;
         e.charset = "utf-8";
         e.src = "//static.dable.io/dist/dablena.min.js";
         _ = a.getElementsByTagName(l)[0];
         _.parentNode.insertBefore(e, _);
       })(window, document, "dablena", "script");
       dablena("init", "003-009-533");
       dablena("track", "CompleteRegistration");`;
      //@ts-ignore
      document.head.appendChild(script);
    } else if (
      isSubmiting.current &&
      !!forSubmitRes?.status &&
      forSubmitRes?.status >= 400
    ) {
      matoTrace(
        isPc ? "pc" : "mobile",
        `regist_fail_${forSubmitRes.status}_${forSubmitRes.msg}`,
        submitEmail
      );
      isSubmiting.current = false;
    }
  }, [dispatch, forSubmitRes, submitEmail, isPc]);

  return (
    <div
      className={styles.modal}
      style={getPcStyle(isPc, pcSignUpModalStyles.modal)}
    >
      {isDownBtnVisible && (
        <ScrollToBottomBtn
          img={downArrowPath}
          onClick={() => scrollToBottom(formRef.current)}
          pcStyle={getPcStyle(isPc, pcSignUpModalStyles.downArrow)}
        />
      )}
      <h1
        className={styles.form_title}
        style={getPcStyle(isPc, pcSignUpModalStyles.formTitle)}
      >
        新規登録で5,000円
      </h1>
      <form
        ref={formRef}
        className={styles.form_container}
        onSubmit={handleSubmit}
        style={getPcStyle(isPc, pcSignUpModalStyles.formContainer)}
        onScroll={handleScroll}
      >
        <FormSubTitle imgPath={formSubTitlePath} isPc={isPc} />
        <div className={styles.input_wrapper}>
          <label
            htmlFor="username"
            style={getPcStyle(isPc, pcSignUpModalStyles.label)}
          >
            アカウント
          </label>
          <input
            id="username"
            name="username"
            type="text"
            placeholder="8〜16桁の小文字英数字の組み合わせ"
            required
            minLength={8}
            maxLength={16}
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
          />
        </div>
        <div className={styles.input_wrapper}>
          <label
            htmlFor="newPassword"
            style={getPcStyle(isPc, pcSignUpModalStyles.label)}
          >
            パスワード
          </label>
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            placeholder="6〜20文字の英数字の組み合わせ"
            required
            minLength={6}
            maxLength={20}
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
            onChange={handleChangePassword}
          />
        </div>
        <div className={styles.input_wrapper}>
          <label
            htmlFor="confirmPassword"
            style={getPcStyle(isPc, pcSignUpModalStyles.label)}
          >
            パスワード確認
          </label>
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder="パスワードを再入力"
            required
            minLength={6}
            maxLength={20}
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
            onChange={handleChangePassword}
          />
        </div>

        <div className={styles.input_wrapper_phone}>
          <label
            htmlFor="phone"
            style={getPcStyle(isPc, pcSignUpModalStyles.label)}
          >
            携帯番号
          </label>
          <input
            className={styles.phone_input}
            id="phone"
            name="phone"
            type="text"
            required
            minLength={10}
            maxLength={11}
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
            onChange={handlePhoneChange}
          />
          <img
            src={japanIconPath}
            alt="japan icon"
            className={styles.phone_icon}
            width={52}
            height={27}
          />
        </div>
        <div className={styles.input_wrapper}>
          <label
            htmlFor="email"
            style={getPcStyle(isPc, pcSignUpModalStyles.label)}
          >
            E-Mail
          </label>
          <input
            id="email"
            name="email"
            type="email"
            placeholder="使用可能なメールアドレス"
            onChange={handleEmailChange}
            required
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
          />
        </div>
        <div className={styles.verify_wrapper}>
          <button
            className={isPc ? styles.button_pc : styles.button}
            type="button"
            onClick={() => {
              handleVerifyEmail(isPc);
              setVerifyEmail(email);
            }}
            disabled={isDisable}
            style={isCorrectEmail && !isStartTimer ? {} : { opacity: "0.2" }}
          >
            {isStartTimer ? `${emailVerifyTimer} s` : "認証コード"}
          </button>
          <input
            id="emailValidateCode"
            name="emailValidateCode"
            type="emailValidateCode"
            placeholder="認証コードを入力"
            required
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
          />
        </div>
        <br />
        <FormSubTitle imgPath={formSubTitleNamesPath} isPc={isPc} />
        <div className={styles.input_wrapper}>
          <label
            htmlFor="romanizationFst"
            style={getPcStyle(isPc, pcSignUpModalStyles.label)}
          >
            姓（ローマ字）
          </label>
          <input
            id="romanizationFst"
            name="romanizationFst"
            type="text"
            onChange={handleRomanizationFstChange}
            required
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
          />
        </div>
        <div className={styles.input_wrapper}>
          <label
            htmlFor="romanization-last"
            style={getPcStyle(isPc, pcSignUpModalStyles.label)}
          >
            名（ローマ字）
          </label>
          <input
            id="romanizationLast"
            name="romanizationLast"
            type="text"
            onChange={handleRomanizationLastChange}
            required
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
          />
        </div>
        <div className={styles.input_wrapper}>
          <label
            htmlFor="nameFst"
            style={getPcStyle(isPc, pcSignUpModalStyles.label)}
          >
            姓（漢字）
          </label>
          <input
            id="nameFst"
            name="nameFst"
            type="text"
            placeholder="身分証明書に記載の通りにご入力ください"
            onChange={handleNameFstChange}
            required
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
          />
        </div>
        <div className={styles.input_wrapper}>
          <label
            htmlFor="nameLast"
            style={getPcStyle(isPc, pcSignUpModalStyles.label)}
          >
            名（漢字）
          </label>
          <input
            id="nameLast"
            name="nameLast"
            type="text"
            placeholder="身分証明書に記載の通りにご入力ください"
            onChange={handleNameLastChange}
            required
            style={getPcStyle(isPc, pcSignUpModalStyles.input)}
          />
        </div>
        <button
          className={styles.submit}
          type="submit"
          disabled={isPasswordValid && isCorrectPhone}
          style={getPcStyle(isPc, pcSignUpModalStyles.submit)}
        >
          <img
            className={styles.submit_button_img}
            src={formSubmitPath}
            alt="submit button"
          />
        </button>
        {formSubmitError && (
          <p className={styles.warning}>{`*${formSubmitError}`}</p>
        )}
        {isPasswordValid && (
          <p className={styles.warning}>
            {`*認証コードが正しくありません、確認の後、再実行してください`}
          </p>
        )}
        {!isCorrectPhone && phone && (
          <p className={styles.warning}>
            {`*携帯番号の形式が正しくありません。もう一度入力してください。`}
          </p>
        )}
        {!!emailVerifyRes?.status &&
          emailVerifyRes?.status >= 400 &&
          email === verifyEmail && (
            <p className={styles.warning}>{`*${emailVerifyRes.msg}`}</p>
          )}
      </form>
    </div>
  );
};

export default SignUpModal;
