import { useEffect, useState } from "react";

const useVerifyPhone = () => {
  const [phone, setPhone] = useState("");
  const [isCorrectPhone, setIsCorrectPhone] = useState(false);
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  useEffect(() => {
    /* 正確 phone 規則
      1. 去掉特殊字元後, 10位數
      2. 去掉特殊字元後, 11位數 且首字為 0
    */
    let regex = /^[a-zA-Z0-9\s]+$/;
    if (!phone) return;
    if (regex.test(phone) && phone.length === 11 && phone[0] === "0") {
      setIsCorrectPhone(true);
      console.log("1");
      return;
    }
    if (regex.test(phone) && phone.length === 10) {
      setIsCorrectPhone(true);
      console.log("2");
      return;
    }
    setIsCorrectPhone(false);
  }, [phone]);

  return {
    phone,
    isCorrectPhone,
    handlePhoneChange,
  };
};
export default useVerifyPhone;
