import PlayGame from "../../components/PlayGame";
import ReduxProvider from "../../store/ReduxProvider";
import { ModalType, ModalVersion } from "../../types/modal";

type PlayGameProviderProps = {
  gameUrl?: string;
  paragraph: { first: string; second: string };
  modalVersion?: ModalVersion;
  defaultModalType?: ModalType;
  isRedirectHome?: boolean;
  TIME_OUT?: number;
  CONTINUE_TIME_OUT?: number;
  promotion_code?: string;
};

const PlayGameProvider = ({
  gameUrl,
  paragraph,
  modalVersion,
  defaultModalType,
  isRedirectHome,
  TIME_OUT,
  CONTINUE_TIME_OUT,
  promotion_code = "",
}: PlayGameProviderProps) => {
  return (
    <ReduxProvider>
      <PlayGame
        paragraph={paragraph}
        gameUrl={gameUrl}
        modalVersion={modalVersion}
        defaultModalType={defaultModalType}
        isRedirectHome={isRedirectHome}
        TIME_OUT={TIME_OUT}
        CONTINUE_TIME_OUT={CONTINUE_TIME_OUT}
        promotion_code={promotion_code}
      />
    </ReduxProvider>
  );
};

export default PlayGameProvider;
