export const modalContainerStyle: React.CSSProperties = {
  width: "628px",
  height: `92vh`,
  minWidth: "628px",
};

export const closeWrapperStyle: React.CSSProperties = {
  height: "52px",
};

