import styles from "./styles.module.scss";
import { matoTrace } from "@katsuwin/share-utils";
import { setModalType } from "../../../../../store/features/modalSlice";
import { useAppDispatch } from "../../../../../hooks/hooks";
import buttonImg from "./images/activityButton.png";
import activityTopImg from "./images/activityTop.png";
import mobileActivityTopImg from "./images/mobileActivityTop.png";

const Activity2Modal = ({ isPc }: { isPc: boolean }) => {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.wrapper}>
      <img
        src={isPc ? activityTopImg : mobileActivityTopImg}
        alt="activity"
        width={512}
        height={383}
        className={styles.activity}
      />
      <p className={styles.activity_text}>
        KatsuWINはニューオープンしたばかりのオンラインカジノで、楽しく当たりやすい、厳選されたたくさんのゲームを提供しています。今すぐ参加していっぱい楽しもう！
      </p>
      <div className={styles.button}>
        <img
          src={buttonImg}
          alt="奮ってご参加ください！"
          width={369}
          height={96}
          onClick={() => {
            dispatch(setModalType("SIGN_UP"));
            matoTrace(
              isPc ? "pc" : "mobile",
              "activity_modal_btn",
              "activity_modal_btn"
            );
          }}
        />
      </div>
    </div>
  );
};

export default Activity2Modal;
