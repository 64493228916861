const redirectUrls = () => {
  // http://katsuwin.co/s/sk4dz
  const redirectToKatsuwin = () => {
    window.location.href = "http://katsuwin.co/s/sk4dz";
  };
  return {
    redirectToKatsuwin,
  };
};
export default redirectUrls;
