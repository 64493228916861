import React from "react";
import styles from "./styles.module.scss";

import { pcSuccessStyles } from "./pcSuccessStyles";
import FormSubTitle from "../FormSubTitle";
import { getPcStyle } from "@katsuwin/share-utils";
import twitterButtonPath from "../../images/button/twitterButton.png";
import officialButtonPath from "../../images/button/officialButton.png";
import formSubTitleSuccessPath from "../../images/formSubTitleSuccess.png";

const SignUpSuccess = ({ isPc }: { isPc: boolean }) => {
  return (
    <div
      className={styles.modal}
      style={getPcStyle(isPc, pcSuccessStyles.modal)}
    >
      <h1
        className={styles.title}
        style={getPcStyle(isPc, pcSuccessStyles.title)}
      >
        おめでとうございます
      </h1>
      <div
        className={styles.form_sub_title_wrapper}
        style={getPcStyle(isPc, pcSuccessStyles.form_sub_title_wrapper)}
      >
        <FormSubTitle imgPath={formSubTitleSuccessPath} isPc={isPc} />
      </div>
      <div
        className={styles.p_wrapper}
        style={getPcStyle(isPc, pcSuccessStyles.pWrapper)}
      >
        <p style={getPcStyle(isPc, pcSuccessStyles.p)}>
          登録が完了いたしました。KatsuWINのグランドオープンイベントにご参加くださり、誠にありがとうございます。
        </p>
        <p style={getPcStyle(isPc, pcSuccessStyles.p)}>
          下部にあるリンクをクリックし、ご登録いただいたアカウントでログインしていただくと、無料体験ボーナス5,000円がゲットでき、当サイト内のゲームでご利用いただけます。
        </p>
        <p style={getPcStyle(isPc, pcSuccessStyles.p)}>
          公式サイトへのご訪問をお待ちしております。
        </p>
      </div>
      <div
        className={styles.links_wrapper}
        style={getPcStyle(isPc, pcSuccessStyles.link_wrapper)}
      >
        <a href="https://www.Katsuwin.io" className={styles.link}>
          <img src={officialButtonPath} alt="twitter" />
        </a>
        <a href="https://twitter.com/katsuwinonline">
          <img className={styles.link} src={twitterButtonPath} alt="twitter" />
        </a>
      </div>
    </div>
  );
};

export default SignUpSuccess;
