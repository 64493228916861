import styles from "./styles.module.scss";
import navBarOpenButtonPath from "../images/navOpenButton.webp";
import navBarLogo from "../images/navbarLogo.webp";
import closeImgPath from "../images/close.webp";
import { useState } from "react";
import { matoTrace } from "@katsuwin/share-utils";
import { useAppDispatch } from "../../../hooks/hooks";
import { setModalType } from "../../../store/features/modalSlice";

type NavBarProps = {
  isMobile?: boolean;
  paragraph: { first: string; second: string };
};

const NavBar = ({ isMobile, paragraph }: NavBarProps) => {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const dispatch = useAppDispatch();
  const showContentJSX = () => {
    return isNavOpen ? (
      <div className={styles.nav_bar_open}>
        <div className={styles.container}>
          <img src={navBarLogo} alt="nav bar" />
          <img
            src={closeImgPath}
            alt="close"
            onClick={() => setIsNavOpen(false)}
            className="cursor-pointer"
          />
        </div>
        <p className={styles.p}>
          {paragraph.first ??
            "遊びまくろうぜ～豊富なゲームを遊びましょう! 安心、安全、楽しいゲームの天国 !"}
        </p>
        <p className={styles.p}>
          {paragraph.second ?? "ハイスピードで登録しよう!GO！"}
        </p>
        <div className={styles.button_wrapper}>
          <button
            onClick={() => {
              dispatch(setModalType("SIGN_UP"));
              matoTrace(
                isMobile ? "mobile" : "pc",
                "login_modal",
                "login_modal"
              );
            }}
            className={styles.login}
          >
            無料ボーナス5000円ゲット！
          </button>
        </div>
      </div>
    ) : (
      <div className={styles.nav_bar_close} onClick={() => setIsNavOpen(true)}>
        <img src={navBarOpenButtonPath} alt="nav bar" />
      </div>
    );
  };
  return (
    <nav className={styles.nav_bar} style={isMobile ? { width: "100vw" } : {}}>
      {showContentJSX()}
    </nav>
  );
};

export default NavBar;
