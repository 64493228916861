import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "../images/close.svg";
import { closeWrapperStyle, modalContainerStyle } from "./pcModalStyle";
import SignUpModal from "./ModalContent/SignUpModalV4";
import SignUpSuccess from "./ModalContent/SignUpSuccess";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { setModalType } from "../../../store/features/modalSlice";
import { getPcStyle } from "@katsuwin/share-utils";
import { ModalCloseType, ModalType } from "../../../types/modal";
import useModal from "../useModal";

type CustomModalProps = {
  type?: "mobile" | "pc";
  modal_type: ModalType;
  set_modal_type: (type: ModalType) => void;
  promotion_code: string;
  mode: "dark" | "light";
  gaId: string;
  adEventTriggerId: string;
  modalCloseType: ModalCloseType;
};
const CustomModal = ({
  type = "mobile",
  modal_type,
  set_modal_type,
  promotion_code,
  mode,
  gaId,
  adEventTriggerId,
  modalCloseType,
}: CustomModalProps) => {
  const dispatch = useAppDispatch();
  const isPc = type === "pc";
  const { modalType } = useAppSelector((state) => state.modal);
  const { handleBgClose, handleCrossClose } = useModal({
    modalCloseType,
    set_modal_type,
  });
  useEffect(() => {
    if (modal_type) {
      dispatch(setModalType(modal_type));
    }
  }, [dispatch, modal_type]);

  if (!modalType) return null;

  const switchShowModalJSX = () => {
    switch (modalType) {
      case "SIGN_UP":
        return (
          <SignUpModal
            isPc={isPc}
            promotion_code={promotion_code}
            mode={mode}
            gaId={gaId}
            adEventTriggerId={adEventTriggerId}
          />
        );
      case "SIGN_UP_SUCCESS":
        return <SignUpSuccess isPc={isPc} mode={mode} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.modal_wrapper} onClick={handleBgClose}>
      <div
        className={styles[`modal_container_${mode}`]}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={getPcStyle(isPc, modalContainerStyle)}
      >
        <div
          className={styles.close_wrapper}
          style={getPcStyle(isPc, closeWrapperStyle)}
        >
          <CloseIcon onClick={handleCrossClose} className={styles.close_icon} />
        </div>
        {switchShowModalJSX()}
      </div>
    </div>
  );
};

export default CustomModal;
