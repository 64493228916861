export const modalContainerStyle: React.CSSProperties = {
  background: `linear-gradient(31deg, #045B92 -35.19%, #3B9CDA 69.89%, #7CB1D2 102.11%)`,
  backgroundSize: "cover",
  width: "628px",
  height: `92vh`,
  minWidth: "628px",
};

export const closeWrapperStyle: React.CSSProperties = {
  height: "52px",
};
export const closeImgStyle: React.CSSProperties = {
  width: "40px",
  height: "40px",
  right: "64px",
  top: "32px",
};
