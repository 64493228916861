import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";
import GameFooter from "./Footer";
import { useDetectDevices } from "@katsuwin/share-hooks";
import CustomModalProvider from "../../Provider/CustomModalProvider";
import { setModalType } from "../../store/features/modalSlice";
import NavBar from "./NavBar";
import "./index.css";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { ModalType, ModalVersion } from "../../types/modal";

const PlayGame = ({
  gameUrl = "",
  paragraph,
  modalVersion = "v1",
  defaultModalType = "SIGN_UP",
  isRedirectHome = true,
  TIME_OUT = 120000,
  CONTINUE_TIME_OUT = 0,
  promotion_code,
}: {
  gameUrl?: string;
  paragraph: { first: string; second: string };
  modalVersion?: ModalVersion;
  defaultModalType?: ModalType;
  isRedirectHome?: boolean;
  TIME_OUT?: number;
  CONTINUE_TIME_OUT?: number;
  promotion_code: string;
}) => {
  const [searchParams] = useSearchParams();
  const url = searchParams.get("game") || "";
  const { isMobile } = useDetectDevices();
  const dispatch = useAppDispatch();
  const { modalType } = useAppSelector((state) => state.modal);
  const navigate = useNavigate();
  const isFstTimeOutFlag = useRef(false);

  useEffect(() => {
    // defaultModalType === "" || "ACTIVITY" 才需要延遲打開 SIGN_UP
    if (defaultModalType !== "" && defaultModalType !== "ACTIVITY_2") return;
    const timerId = setTimeout(() => {
      dispatch(setModalType("SIGN_UP"));
      isFstTimeOutFlag.current = true;
      if (isRedirectHome) {
        navigate("/");
      }
    }, TIME_OUT);

    return () => {
      clearTimeout(timerId);
    };
  }, [navigate, dispatch, TIME_OUT, isRedirectHome, defaultModalType]);

  useEffect(() => {
    if (CONTINUE_TIME_OUT && isFstTimeOutFlag.current && modalType === "") {
      const timerId = setTimeout(() => {
        dispatch(setModalType("SIGN_UP"));
      }, CONTINUE_TIME_OUT);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [CONTINUE_TIME_OUT, dispatch, modalType]);

  useEffect(() => {
    (() => {
      const e = document.getElementById("mobile-main");
      e?.style.setProperty("height", `${window.innerHeight - 97}px`);
    })();
  }, []);

  return (
    <>
      <div
        className={
          isMobile ? styles.mobile_play_wrapper : styles.pc_play_wrapper
        }
      >
        <NavBar isMobile={isMobile} paragraph={paragraph} />
        <div id="mobile-main" className={styles.iframe_container}>
          {(gameUrl || url) && (
            <iframe
              className={styles.iframe}
              src={gameUrl || url}
              scrolling="no"
              title="katsuwinfree"
            />
          )}
        </div>

        <GameFooter isMobile={isMobile} />
      </div>
      <CustomModalProvider
        promotion_code={promotion_code}
        modal_type={defaultModalType}
        set_modal_type={(type) => setModalType(type)}
        modal_version={modalVersion}
      />
    </>
  );
};

export default PlayGame;
