import "./styles.css";
import { useDetectDevices } from "@katsuwin/share-hooks";
import { ModalCloseType, ModalType, ModalVersion } from "../../types/modal";
import ReduxProvider from "../../store/ReduxProvider";
import CustomModal from "../../components/CustomModal/v1";
import CustomModalV2 from "../../components/CustomModal/v2";
import CustomModalV3 from "../../components/CustomModal/v3";
import CustomModalV4 from "../../components/CustomModal/v4";
import CustomModalV5 from "../../components/CustomModal/v5";

const CustomModalProvider = ({
  modal_type = "",
  set_modal_type,
  modal_version = "v1",
  promotion_code = "",
  mode = "dark",
  gaId = "",
  adEventTriggerId = "",
  modalCloseType = "ALLOW_CLOSE",
}: {
  modal_type: ModalType;
  set_modal_type: (type: ModalType) => void;
  modal_version?: ModalVersion;
  promotion_code?: string;
  mode?: "dark" | "light";
  gaId?: string;
  adEventTriggerId?: string;
  modalCloseType?: ModalCloseType;
  // process.env.REACT_APP_PROMOTION_CODE
}) => {
  const { isMobile } = useDetectDevices();
  const switchCustomModalVersion = () => {
    switch (modal_version) {
      case "v1":
        return (
          <CustomModal
            modal_type={modal_type}
            type={isMobile ? "mobile" : "pc"}
            set_modal_type={set_modal_type}
            promotion_code={promotion_code}
            gaId={gaId}
            adEventTriggerId={adEventTriggerId}
            modalCloseType={modalCloseType}
          />
        );
      case "v2":
        return (
          <CustomModalV2
            modal_type={modal_type}
            type={isMobile ? "mobile" : "pc"}
            set_modal_type={set_modal_type}
            modalCloseType={modalCloseType}
          />
        );
      case "v3":
        return (
          // for v20 - v22 使用
          <CustomModalV3
            modal_type={modal_type}
            type={isMobile ? "mobile" : "pc"}
            set_modal_type={set_modal_type}
            promotion_code={promotion_code}
            gaId={gaId}
            adEventTriggerId={adEventTriggerId}
            modalCloseType={modalCloseType}
          />
        );
      case "v4":
        return (
          // for v23 使用
          <CustomModalV4
            modal_type={modal_type}
            type={isMobile ? "mobile" : "pc"}
            set_modal_type={set_modal_type}
            promotion_code={promotion_code}
            mode={mode}
            gaId={gaId}
            adEventTriggerId={adEventTriggerId}
            modalCloseType={modalCloseType}
          />
        );
      case "v5":
        return (
          // for v41 使用
          <CustomModalV5
            modal_type={modal_type}
            type={isMobile ? "mobile" : "pc"}
            set_modal_type={set_modal_type}
            promotion_code={promotion_code}
            mode={mode}
            gaId={gaId}
            adEventTriggerId={adEventTriggerId}
            modalCloseType={modalCloseType}
          />
        );
      default:
        return <></>;
    }
  };
  return <ReduxProvider>{switchCustomModalVersion()}</ReduxProvider>;
};

export default CustomModalProvider;
