import { useState } from "react";

const usePersonalInfo = () => {
  const [romanizationFst, setRomanizationFst] = useState("");
  const [romanizationLast, setRomanizationLast] = useState("");
  const [nameFst, setNameFst] = useState("");
  const [nameLast, setNameLast] = useState("");
  const handleRomanizationFstChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRomanizationFst(event.target.value);
  };
  const handleRomanizationLastChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRomanizationLast(event.target.value);
  };
  const handleNameFstChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFst(event.target.value);
  };
  const handleNameLastChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameLast(event.target.value);
  };
  return {
    romanizationFst,
    handleRomanizationFstChange,
    romanizationLast,
    handleRomanizationLastChange,
    nameFst,
    setNameFst,
    handleNameFstChange,
    nameLast,
    handleNameLastChange,
  };
};
export default usePersonalInfo;
