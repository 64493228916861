import { useAppDispatch } from "../../hooks/hooks";
import { setModalType } from "../../store/features/modalSlice";
import { ModalCloseType, ModalType } from "../../types/modal";

type UseModalProps = {
  modalCloseType: ModalCloseType;
  set_modal_type: (type: ModalType) => void;
};
const useModal = ({ modalCloseType, set_modal_type }: UseModalProps) => {
  const dispatch = useAppDispatch();
  /* 
    關閉 modal 事件
    1. 只能點擊 modal 叉叉關閉 ONLY_CROSS
    2. 只能點擊 modal 背景關閉 ONLY_BG
    3. 點擊叉叉與背景要關閉 ALLOW_CLOSE
    4. 點擊叉叉與背景不能關閉 DISABLE_CLOSE
    ModalCloseType = "ONLY_CROSS" | "ONLY_BG" | "ALLOW_CLOSE" | DISABLE_CLOSE"
  */
  const handleBgClose = () => {
    switch (modalCloseType) {
      case "DISABLE_CLOSE":
      case "ONLY_CROSS":
        return;
      case "ALLOW_CLOSE":
      case "ONLY_BG":
        dispatch(setModalType(""));
        set_modal_type("");
        return;
      default:
        dispatch(setModalType(""));
        set_modal_type("");
    }
  };
  const handleCrossClose = () => {
    switch (modalCloseType) {
      case "DISABLE_CLOSE":
      case "ONLY_BG":
        return;
      case "ALLOW_CLOSE":
      case "ONLY_CROSS":
        dispatch(setModalType(""));
        set_modal_type("");
        return;
      default:
        dispatch(setModalType(""));
        set_modal_type("");
    }
  };
  return {
    handleBgClose,
    handleCrossClose,
  };
};
export default useModal;
