import styles from "./styles.module.scss";
import { matoTrace } from "@katsuwin/share-utils";
import { useAppDispatch } from "../../../hooks/hooks";
import { setModalType } from "../../../store/features/modalSlice";
import homeIcon from '../images/homeIcon.png'

type GameFooterProps = {
  isMobile?: boolean;
};

const GameFooter = ({ isMobile }: GameFooterProps) => {
  const dispatch = useAppDispatch();

  return (
    <button
      onClick={() => {
        dispatch(setModalType("SIGN_UP"));
        matoTrace(isMobile ? "mobile" : "pc", "login_modal", "login_modal");
      }}
      className={styles.footer}
      style={isMobile ? mobileStyle : {}}
    >
      <img src={homeIcon} alt="home" width={20} height={20} />
      <p>無料ボーナス5000円ゲット！</p>
    </button>
  );
};

const mobileStyle = {
  height: "42px",
  width: "100vw",
  borderRadius: "0px",
  bottom: "0",
};

export default GameFooter;
