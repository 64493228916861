const isAtBottom = (event: React.UIEvent<HTMLFormElement>) => {
  const scrollContainer = event.target as HTMLElement;

  if (!event.target) return false;

  return !(
    Math.abs(
      scrollContainer.scrollHeight -
        (scrollContainer.scrollTop + scrollContainer.clientHeight)
    ) <= 1
  );
};

export default isAtBottom;
