import React from "react";
import styles from "./styles.module.scss";
import { pcSuccessStyles } from "./pcSuccessStyles";
import { getPcStyle } from "@katsuwin/share-utils";
import officialHomeButton from "../../images/button/officialHome.png";
import officialTwitterButton from "../../images/button/officialTwitter.png";
const SignUpSuccess = ({ isPc }: { isPc: boolean }) => {
  return (
    <div
      className={styles.modal}
      style={getPcStyle(isPc, pcSuccessStyles.modal)}
    >
      <h1
        className={styles.title}
        style={getPcStyle(isPc, pcSuccessStyles.title)}
      >
        おめでとうございます
      </h1>
      <div
        className={styles.p_wrapper}
        style={getPcStyle(isPc, pcSuccessStyles.pWrapper)}
      >
        <p style={getPcStyle(isPc, pcSuccessStyles.p)}>
          登録が完了いたしました。KatsuWINのグランドオープンイベントにご参加くださり、誠にありがとうございます。
        </p>
        <p style={getPcStyle(isPc, pcSuccessStyles.p)}>
          下部にあるリンクをクリックし、ご登録いただいたアカウントでログインしていただくと、無料体験ボーナス5,000円がゲットでき、当サイト内のゲームでご利用いただけます。
        </p>
        <p style={getPcStyle(isPc, pcSuccessStyles.p)}>
          公式サイトへのご訪問をお待ちしております。
        </p>
      </div>
      <a href="https://www.katsuwin.io/" className={styles.link_wrapper}>
        <img
          src={officialHomeButton}
          alt="twitter"
          width={isPc ? 307 : 191}
          height={isPc ? 90 : 56}
        />
      </a>
      <a
        href="https://twitter.com/katsuwinonline"
        className={styles.link_wrapper}
      >
        <img
          src={officialTwitterButton}
          alt="twitter"
          width={isPc ? 307 : 191}
          height={isPc ? 90 : 56}
        />
      </a>
      {/* <div className={styles.media_block}>
        <h3>事前登録イベントを共有</h3>
        <SocialIcons socialIconsStyle={socialIconsStyle} />
      </div> */}
    </div>
  );
};

export default SignUpSuccess;
