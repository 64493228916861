import styles from "./styles.module.scss";
import headerPath from "../../images/activityHeader.png";
import contentPath from "../../images/activityContent.png";
import buttonPath from "../../images/button/activityButton.png";
import { matoTrace } from "@katsuwin/share-utils";
import { redirectUrls } from "@katsuwin/share-utils";

const ActivityModal = ({ isPc }: { isPc: boolean }) => {
  const { redirectToKatsuwin } = redirectUrls();
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={headerPath} alt="事前登録 熱狂開催" />
      </div>
      <div className={styles.content}>
        <img
          src={contentPath}
          alt="5000円ゲットできる
体験ボーナスイベントは 9/22まで開催"
        />
      </div>
      <div className={styles.button}>
        <img
          src={buttonPath}
          alt="奮ってご参加ください！"
          onClick={() => {
            matoTrace(
              isPc ? "pc" : "mobile",
              "activity_modal_btn",
              "activity_modal_btn"
            );
            redirectToKatsuwin();
          }}
        />
      </div>
    </div>
  );
};

export default ActivityModal;
