import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { setModalType } from "../../../store/features/modalSlice";
import { ModalCloseType, ModalType } from "../../../types/modal";
import useModal from "../useModal";
import SignUpForm from "../../SignUpForm";
import SignUpSuccess from "./ModalContent/SignUpSuccess";

type CustomModalProps = {
  type?: "mobile" | "pc";
  modal_type: ModalType;
  set_modal_type: (type: ModalType) => void;
  promotion_code: string;
  mode: "dark" | "light";
  gaId: string;
  adEventTriggerId: string;
  modalCloseType: ModalCloseType;
};
const CustomModal = ({
  type = "mobile",
  modal_type,
  set_modal_type,
  promotion_code,
  mode,
  gaId,
  adEventTriggerId,
  modalCloseType = "ALLOW_CLOSE",
}: CustomModalProps) => {
  const dispatch = useAppDispatch();
  const isPc = type === "pc";
  const { modalType } = useAppSelector((state) => state.modal);

  const { handleCrossClose } = useModal({
    modalCloseType,
    set_modal_type,
  });

  const switchShowModalJSX = () => {
    switch (modalType) {
      case "SIGN_UP":
        return (
          <SignUpForm
            isPc={isPc}
            gaId={gaId}
            promotionCode={promotion_code}
            setToSuccessView={() => dispatch(setModalType("SIGN_UP_SUCCESS"))}
          />
        );
      case "SIGN_UP_SUCCESS":
        return <SignUpSuccess />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (modal_type) {
      dispatch(setModalType(modal_type));
    }
  }, [dispatch, modal_type]);

  if (!modalType) return null;

  return (
    <div className={styles.modal_wrapper}>
      <div className={styles.modal_container}>{switchShowModalJSX()}</div>
      <button onClick={handleCrossClose}>登録終了する</button>
    </div>
  );
};

export default CustomModal;
